import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import { format } from 'date-fns';
// import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import tenantBillDetails from '../../../payable/apInvoice/invoiceEntry/viewInvoiceEntry';
import store from '@/store';
import { ModelSelect, BasicSelect } from 'vue-search-select';
export default {
  name: 'applyReceiptDetails',
  watch: {
    selectAllCheckBoxApplied: function() {
      this.checkUncheckAllApplied();
    },
    selectedApplicationType: function() {
      if (this.selectedApplicationType.value === 'AUTOMATIC') {
        this.unAppliedReceiptDetailsFields.shift();
        this.loopFunction(this.unAppliedReceiptDetailsData);
      } else {
        this.unAppliedReceiptDetailsFields.unshift({
          key: 'selectBoxUnapplied',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        });
        this.unAppliedReceiptDetailsData.forEach((key, index) => {
          // key.adj_amount = 0;
          this.selectBoxCheckedUnapplied(false, index, key, true);
        });
        this.unappliedAmount = parseFloat(this.actualBalanceAmount).toFixed(2); //
        this.balReceiptAmount = this.actualBalanceAmount; //
        // this.unAppliedReceiptDetailsFields[0].key = 'selectBoxUnapplied';
      }
    },
    currentPage: function() {
      // this.getUnAppliedDetailsById();
      this.getUnAppliedTransactionDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      // this.getUnAppliedDetailsById();
      this.getUnAppliedTransactionDetails();
    }
  },
  components: {
    DatePicker,
    tenantBillDetails,
    ModelSelect,
    BasicSelect
  },
  props: ['applyReceiptRowData', 'trxTypeDtlId', 'sendObj'],
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: commonHelper.getPageOption(),
      actualBalanceAmount: 0,
      loader: false,
      isBusy: true,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      validateMsg: null,
      unsubscribe: null,
      customerSite: null,
      receiptNum: null,
      valueDate: null,
      clearDate: null,
      amount: null,
      balReceiptAmount: null,
      formattedBalReceiptAmount: '0.00',
      // applicationGlDate: null,
      module: null,
      moduleId: null,
      subUnit: null,
      unit: null,
      applyType: null,
      customerName: null,
      site: null,
      applicationMode: null,
      selectAllCheckBoxApplied: false,
      unAppliedReceiptDetailsData: [
        {
          adj_amount: null,
          gl_date: null,
          value_date: null
        }
      ],
      unAppliedReceiptDetailsFields: [
        // {
        //   key: 'selectBoxUnapplied',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'primary'
        // },
        {
          key: 'trx_number'
        },
        {
          key: 'line_type'
        },
        {
          key: 'trx_date'
        },
        {
          key: 'description'
        },
        {
          key: 'trx_amount',
          label: 'Trx Amt'
        },
        {
          key: 'applied_amount',
          label: 'App Amt'
        },
        {
          key: 'outstanding_amount',
          label: 'OS Amt'
        },
        {
          key: 'value_date'
        },
        {
          key: 'gl_date',
          label: 'App. GL Date'
        },
        {
          key: 'adj_amount',
          label: 'Application Amount'
        }
      ],
      appliedDetailsData: [],
      appliedDetailsFields: [
        {
          key: 'selectBoxApplied',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'trx_number'
        },
        {
          key: 'trx_type'
        },
        {
          key: 'value_date'
        },
        {
          key: 'description'
        },
        // {
        //   key: 'trx_amount',
        //   label: 'Amount'
        // },
        {
          key: 'gl_date',
          label: 'App. GL Date'
        },
        {
          key: 'application_amount'
        },
        {
          key: 'app_dtl_desc',
          label: 'Refund Description'
        }
        //   key: 'balance'
        // },
        // {
      ],
      activityDetailsData: [
        {
          activity_details_vset: null,
          activity_details_vset_meaning: null,
          activity_type_vset: null,
          activity_type_vset_meaning: null,
          amount: null,
          gl_date: null,
          value_date: null,
          description: null
        }
      ],
      activityDetailsFields: [
        {
          key: 'activity_details_vset_meaning',
          label: 'Activity Details'
        },
        {
          key: 'activity_type_vset_meaning',
          label: 'Activity Type'
        },
        {
          key: 'amount'
        },
        {
          key: 'value_date'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'description'
        }
        // {
        //   key: 'add',
        //   stickyColumn: true
        // },
        // {
        //   key: 'remove',
        //   stickyColumn: true
        // }
      ],
      accountingDetailsList: [],
      accountdingDetailsField: [
        {
          key: 'document_num',
          label: 'Receipt Num'
        },
        {
          key: 'ccid_code',
          label: 'CCID Code'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'period_name',
          label: 'Period'
        },
        {
          key: 'transaction_dr'
        },
        {
          key: 'transaction_cr'
        },
        {
          key: 'base_dr'
        },
        {
          key: 'base_cr'
        },
        {
          key: 'account_class'
        },
        {
          key: 'gl_posting_flag',
          label: 'Posting Flag'
        }
      ],
      showValueSetModal: false,
      parent_value_set_id: null,
      specIndex: null,
      depVsetParam: null,
      tempVar: null,
      billHdrId: null,
      showTenantBillModal: false,
      selectedApplicationType: {
        value: null,
        text: null
      },
      applicationTypeList: [],
      trxNo:{
        text:null,
        value:null
      },
      unappliedAmount: 0,
      trxHdrId: null,
      trxAmt: null,
      osAmt: null,
      detailsLevel: null,
      trxHeaderId: null
    };
  },
  // validations: {
  //   unAppliedReceiptDetailsData: {
  //     $each: {
  //       value_date: { required }
  //     }
  //   }
  // },
  computed: {
    getFormattedBalAmount() {
      const formattedBalReceiptAmount = this.balReceiptAmount
        ? this.balReceiptAmount.toLocaleString('en-IN', {
            minimumFractionDigits: 2
          })
        : '0.00';
      return formattedBalReceiptAmount || 0.0;
    },
    getFormattedInsAmount() {
      const formattedInsReceiptAmount = this.amount
        ? this.amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })
        : '0.00';
      return formattedInsReceiptAmount || 0.0;
    }
  },
  mounted() {
    this.detailsLevel = this.applyReceiptRowData.dtl_level
    this.getReceiptApplicatonTypes();
    if (this.applyReceiptRowData) {
      // if (this.applyReceiptRowData.receipt_cn === 'Security Advance') {
      if (this.applyReceiptRowData.receipt_cn) {
        this.appliedDetailsFields.splice(2, 0, {
          key: 'ap_invoice_num',
          label: 'Invoice Number'
        });
        // this.appliedDetailsFields.push({
        //   key: 'ap_invoice_num',
        //   label: 'Invoice Number'
        // });
      }
    }
    if (this.applyReceiptRowData || this.sendObj) {
      this.getApplyReceiptTransactionHdrById();
      // this.getReceiptAccountingList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getTrxNoData() {
      // this.getUnAppliedDetailsById();
      if(this.detailsLevel === "N"){
        this.getUnAppliedTransactionDetails();
      } else {
        this.getApplyUnAppliedTransactionDetails();
      }
      },
    showHideTenantBillModal(flag, billHdrId) {
      this.showTenantBillModal = flag;
      this.billHdrId = billHdrId;
    },
    addEditActivityApplyDetails() {
      const payload = {
        activity_id: this.activityId
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/addEditActivityApplyDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if(this.detailsLevel === 'N'){
              this.getAppliedDetailsId(this.applyReceiptRowData.receipt_hdr_id);
            } else{
              this.getAppliedSummaryLevel(this.applyReceiptRowData.receipt_hdr_id)
             }
            } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getApplyReceiptTransactionHdrById() {
      const payload = {
        params: {
          trx_type_dtl_id: this.trxTypeDtlId
            ? this.trxTypeDtlId
            : this.sendObj.module_id == 14
            ? '1'
            : this.sendObj.module_id == 20
            ? '8'
            : null,
          module_id: this.applyReceiptRowData
            ? this.applyReceiptRowData.module_id
            : this.sendObj
            ? this.sendObj.module_id
            : null
        },
        receipt_hdr_id: this.sendObj
          ? this.sendObj.receipt_hdr_id
          : this.applyReceiptRowData.trx_type_dtl_id === 12
          ? this.applyReceiptRowData.cn_rcpt_id
          : this.applyReceiptRowData.receipt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getApplyReceiptTransactionHdrById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.customerSiteId = results.customer_site_id;
            this.customerId = results.customer_id;
            this.receiptNum = results.receipt_number;
            this.valueDate = results.value_date;
            this.moduleId = results.module_id;
            this.module = results.module;
            // this.applicationGlDate = results.gl_date;
            this.customerSite = results.customer_site;
            this.customerName = results.customer_name;
            this.entityId = results.entity_id;
            this.receiptHdrId = results.receipt_hdr_id;
            this.subUnit = results.sub_unit_name;
            this.unit = results.unit_name;
            this.clearDate = results.clearing_date;
            this.balReceiptAmount = parseFloat(results.bal_receipt_amount);
            this.unappliedAmount = parseFloat(this.balReceiptAmount).toFixed(2);
            this.actualBalanceAmount = parseFloat(results.bal_receipt_amount);
            this.amount = commonHelper.unformatAmount(
              results.instrument_amount
            );
            this.cnReceiptId = results.cn_rcpt_id;
            this.applicationMode =
              results.receipt_cn === 'RECEIPT'
                ? 'RCPT'
                : results.receipt_cn === 'SEC ADV'
                ? 'SEC ADV'
                : 'CN';
            // this.receiptCn = results.receipt_cn;
            if (this.applyReceiptRowData) {
              if(this.detailsLevel === 'N'){
                this.getAppliedDetailsId(this.applyReceiptRowData.receipt_hdr_id);
               } else {
                this.getAppliedSummaryLevel(this.applyReceiptRowData.receipt_hdr_id);
               }
              
            }
            if (this.sendObj) {
              if(this.detailsLevel === 'N'){
                this.getAppliedDetailsId(this.sendObj.receipt_hdr_id);
              } else {
                this.getAppliedSummaryLevel(this.sendObj.receipt_hdr_id);
               }
             }
            this.getReceiptAccountingList();
            if (this.applicationMode === 'SEC ADV') {
              this.getUnAppliedDetailsByIdForSecAdv();
            } else {
              // this.getUnAppliedDetailsById();
              if (this.detailsLevel === 'N'){
                this.getUnAppliedTransactionDetails();
              } else {
                this.getApplyUnAppliedTransactionDetails();
              }
             }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAppliedDetailsId(cn_rcpt_id, type) {
      const payload = {
        receipt_hdr_id: cn_rcpt_id,
        application_mode_vset: this.applicationMode
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getAppliedDetailsId', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.appliedDetailsData = response.data.data;
            if (type) {
              this.getReceiptApplicatonTypes();
              // this.getUnAppliedDetailsById();
              if (this.detailsLevel === 'N'){
                this.getUnAppliedTransactionDetails();
              } else {
                this.getApplyUnAppliedTransactionDetails();
              }
               }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUnAppliedDetailsByIdForSecAdv() {
      const payload = {
        module_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.module_id
          : this.sendObj
          ? this.sendObj.module_id
          : null,
        customer_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.customer_id
          : this.sendObj
          ? this.sendObj.customer_id
          : null,
        // customer_site_id: this.applyReceiptRowData ? this.applyReceiptRowData.customer_site_id : this.sendObj ? this.sendObj.customer_site_id : null,
        entity_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.entity_id
          : this.sendObj
          ? this.sendObj.entity_id
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getUnAppliedDetailsByIdForSecAdv', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.unapplied_details;
            this.unAppliedReceiptDetailsData = results
              .filter(elem => elem.outstanding_amount > 0)
              .map(data => {
                data.adj_amount = 0;
                data.value_date = this.applyReceiptRowData.value_date;
                data.gl_date = this.applyReceiptRowData.gl_date;
                return data;
              });
            this.isBusy = false;
            this.getDefaultDate(store.state.shared.moduleId);
          }
        })
        .catch(() => {
          this.loader = false;
          this.isBusy = false;
        });
    },
    // getUnAppliedDetailsById() {
    //   if (this.trxNo) {
    //     let filterData = this.unAppliedReceiptDetailsData.filter(item => {
    //       if (item.trx_number == this.trxNo) {
    //         return item;
    //       }
    //     });
    //     this.trxHdrId = filterData[0].trx_hdr_id;
    //   }
    //   const payload = {
    //
    //
    //     module_id: this.applyReceiptRowData
    //       ? this.applyReceiptRowData.module_id
    //       : this.sendObj
    //       ? this.sendObj.module_id
    //       : null,
    //     customer_id: this.applyReceiptRowData
    //       ? this.applyReceiptRowData.customer_id
    //       : this.sendObj
    //       ? this.sendObj.customer_id
    //       : null,
    //     // customer_site_id: this.applyReceiptRowData ? this.applyReceiptRowData.customer_site_id : this.sendObj ? this.sendObj.customer_site_id : null,
    //     entity_id: this.applyReceiptRowData
    //       ? this.applyReceiptRowData.entity_id
    //       : this.sendObj
    //       ? this.sendObj.entity_id
    //       : null,
    //     trx_id: this.trxHdrId ? this.trxHdrId : null
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('applyReceipt/getUnAppliedDetailsById', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //
    //
    //         const results = response.data.data.unapplied_details;
    //         this.unAppliedReceiptDetailsData = results
    //         .filter(elem => elem.outstanding_amount > 0)
    //           .map(data => {
    //             data.adj_amount = 0;
    //             data.value_date = this.applyReceiptRowData.value_date;
    //             data.gl_date = this.applyReceiptRowData.gl_date;
    //             return data;
    //           });
    //         this.loopFunction(this.unAppliedReceiptDetailsData);
    //         this.isBusy = false;
    //         this.getDefaultDate(store.state.shared.moduleId);
    //         if (this.trxHdrId && this.trxNo) {
    //           this.getOsTrxAmt();
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //       this.isBusy = false;
    //     });
    // },

    getUnAppliedTransactionDetails() {
      // if (this.trxNo.value) {
      //   let filterData = this.unAppliedReceiptDetailsData.filter(item => {
      //     if (item.trx_number == this.value) {
      //       return item;
      //     }
      //   });
      //   this.trxHdrId = filterData[0].trx_hdr_id;
      // }
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.module_id
          : this.sendObj
          ? this.sendObj.module_id
          : null,
        customer_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.customer_id
          : this.sendObj
          ? this.sendObj.customer_id
          : null,
        // customer_site_id: this.applyReceiptRowData ? this.applyReceiptRowData.customer_site_id : this.sendObj ? this.sendObj.customer_site_id : null,
        entity_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.entity_id
          : this.sendObj
          ? this.sendObj.entity_id
          : null,
        trx_id: this.trxNo.value
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getUnAppliedTransactionDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            const results = response.data.data.page;
          this.unAppliedReceiptDetailsData = results
              .filter(elem => elem.outstanding_amount > 0)
              .map(data => {
                data.adj_amount = 0;
                data.value_date = this.applyReceiptRowData.value_date;
                data.gl_date = this.applyReceiptRowData.gl_date;
                return data;
              });
            this.loopFunction(this.unAppliedReceiptDetailsData);
            this.isBusy = false;
            this.getDefaultDate(store.state.shared.moduleId);
            if (this.trxHdrId && this.trxNo.text) {
              this.getOsTrxAmt();
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.isBusy = false;
        });
    },
    loopFunction(array) {
      if (this.selectedApplicationType.value === 'AUTOMATIC') {
        // this.unAppliedReceiptDetailsFields[0].key = '';
        for (let i = 0; i < array.length; i++) {
          this.selectBoxCheckedUnapplied(true, i, array[i]);
        }
      }
    },
    getOsTrxAmt() {
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getOsTrxAmt', this.trxHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.osAmt = response.data.data[0].os_bal;
            this.trxAmt = response.data.data[0].trx_amount;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDefaultDate(moduleId) {
      const payload = {
        module_id: moduleId,
        le_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.org_le_id
          : this.sendObj
          ? this.sendObj.le_id
          : null,
        entity_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.org_ou_id
          : this.sendObj
          ? this.sendObj.entity_id
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getModuleBasedPeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.unAppliedReceiptDetailsData.map(data => {
              data.gl_date = response.data.data;
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValidatePeriodDate(glDate, index, transactionFlag) {
      const payload = {
        module_id: store.state.shared.moduleId,
        le_id: this.applyReceiptRowData.org_le_id,
        entity_id: this.applyReceiptRowData.org_ou_id,
        gl_date: glDate
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              if (transactionFlag === 'ACTIVITY_DATA') {
                this.activityDetailsData[index].gl_date = null;
              }
              transactionFlag === 'APPLY_DATA'
                ? (this.appliedDetailsData[index].gl_date = format(
                    new Date(),
                    appStrings.DATEFNSFORMAT
                  ))
                : transactionFlag === 'UNAPPLY_DATA'
                ? (this.unAppliedReceiptDetailsData[index].gl_date = format(
                    new Date(),
                    appStrings.DATEFNSFORMAT
                  ))
                : null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addVoidPaymentRequest() {
      const payload = {
        gl_date: this.unAppliedReceiptDetailsData.gl_date
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/addVoidPaymentRequest', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectBoxCheckedUnapplied(flag, index, unapplyRow, type) {
      this.unAppliedReceiptDetailsData[index].selectBoxUnapplied = flag;
     
      if (flag) {
        this.getValidatePeriodDate(
          this.unAppliedReceiptDetailsData[index].gl_date,
          index,
          'UNAPPLY_DATA'
        );
        this.setAdjAmount(this.unAppliedReceiptDetailsData[index], flag);
      }
      if (!flag) {
        if (type) {
          if (!this.trxNo.text) {
            //
            this.balReceiptAmount += unapplyRow.adj_amount;
          } //
        } else {
          this.balReceiptAmount +=
            Math.sign(this.balReceiptAmount) * unapplyRow.adj_amount;
        }
        unapplyRow.adj_amount = 0;
      }
    },
    selectBoxCheckedApplied(flag, index) {
  this.appliedDetailsData[index].selectBoxApplied = flag;
      if (flag) {
        this.getValidatePeriodDate(
          this.appliedDetailsData[index].gl_date,
          index,
          'APPLY_DATA'
        );
      }
   },
    checkUncheckAllApplied(index) {
      this.appliedDetailsData = this.appliedDetailsData.map(data => {
        data.selectBoxApplied = this.selectAllCheckBoxApplied;
        if (this.selectAllCheckBoxApplied) {
          this.getValidatePeriodDate(data.gl_date, index, 'APPLY_DATA');
        }
        return data;
      });
    },
    createAppliedRcptDtlArrFun(arr, processFlag) {
      const filterArr =
        processFlag === 'A'
          ? arr.filter(data => data.selectBoxUnapplied && data.adj_amount)
          : arr.filter(data => data.selectBoxApplied);
      if (!filterArr && !filterArr.length && processFlag === 'U') {
        return alert('Please select atleast one record from Applied Details');
      } else if (!filterArr && !filterArr.length && processFlag === 'A') {
        return alert('Please select atleast one record from Unapplied Details');
      }
      const recepitDtlArr = filterArr.map(key => {
        return {
          trx_dtl_id: key.trx_dtl_id,
          gl_date: key.gl_date,
          trx_hdr_id: key.trx_hdr_id,
          value_date: key.value_date,
          application_type: processFlag,
          amount: key.adj_amount
            ? key.adj_amount
            : commonHelper.unformatAmount(key.application_amount),
          application_dtl_id: key.application_dtl_id
        };
      });
      // return recepitDtlArr;
      const filterActivityArr = this.activityDetailsData.filter(data => {
        if (data.activity_details_vset !== null) {
          return data;
        }
      });
      const activityDtlArr = filterActivityArr.map(key => {
        return {
          activity_id: key.activity_details_vset,
          activity_type_vset: key.activity_type_vset,
          amount: key.amount,
          gl_date: key.gl_date,
          value_date: key.value_date,
          app_dtl_desc: key.description,
          application_type: processFlag
        };
      });
      return recepitDtlArr.concat(activityDtlArr);
    },
    getApplyUnapplyById(processFlag) {
      let details = [];
      if (processFlag === 'A') {
        details = this.createAppliedRcptDtlArrFun(
          this.unAppliedReceiptDetailsData,
          processFlag
        );
      } else if (processFlag === 'U') {
        details = this.createAppliedRcptDtlArrFun(
          this.appliedDetailsData,
          processFlag
        );
      }
      if (!details && !details.length) {
        return;
      }
      const payload = {
        cn_receipt_id:
          this.receiptHdrId || this.applyReceiptRowData.receipt_hdr_id,
        application_mode_vset: this.applicationMode,
        receipt_applied_details: details ? details : null,
        dtl_level: this.detailsLevel,
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getApplyUnapplyById', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            processFlag === 'A'
              ? (this.responseMsg = response.data.message)
              : (this.responseMsg = 'Receipt is unapplied Successfully');
            // this.responseMsg = response.data.message;
            this.getApplyReceiptTransactionHdrById(
              this.applyReceiptRowData.receipt_hdr_id
            );
            if(this.detailsLevel === 'N'){
              this.getAppliedDetailsId(
                this.applyReceiptRowData.receipt_hdr_id,
                true
              );
             } else {
              this.getAppliedSummaryLevel(this.applyReceiptRowData.receipt_hdr_id, true)
            }
             if (this.applicationMode === 'SEC ADV') {
              this.getUnAppliedDetailsByIdForSecAdv();
            } else {
              // this.getUnAppliedDetailsById();
              if (this.detailsLevel === 'N'){
                this.getUnAppliedTransactionDetails();
               } else {
                this.getApplyUnAppliedTransactionDetails();
               }
               }
            this.activityDetailsData[0].activity_details_vset = null;
            this.activityDetailsData[0].activity_details_vset_meaning = null;
            this.activityDetailsData[0].activity_type_vset = null;
            this.activityDetailsData[0].activity_type_vset_meaning = null;
            this.activityDetailsData[0].description = null;
            this.activityDetailsData[0].amount = null;
            this.activityDetailsData[0].gl_date = null;
            this.activityDetailsData[0].value_date = null;
            // this.getUnAppliedDetailsById();
            this.trxNo.text = null;
            this.trxAmt = null;
            this.osAmt = null;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    realTimeCalculation(row) {
      if (typeof row.adj_amount !== 'number') {
        row.adj_amount = 0;
      }
      const totalAmount = this.unAppliedReceiptDetailsData.reduce(
        (prev, curr) =>
          prev + Math.sign(this.balReceiptAmount) * curr.adj_amount,
        0
      );
      this.balReceiptAmount = this.actualBalanceAmount - totalAmount;
    },
    validateAdjAmt(unappliedRow) {
      this.tempVar = this.balReceiptAmount;
      if (unappliedRow.outstanding_amount < unappliedRow.adj_amount) {
        this.balReceiptAmount +=
          Math.sign(this.balReceiptAmount) * unappliedRow.adj_amount;
        unappliedRow.adj_amount = 0;
        return alert(
          'Adjustment amount should not be greater than Outstanding Amount'
        );
      } else if (Math.abs(this.actualBalanceAmount) < unappliedRow.adj_amount) {
        unappliedRow.adj_amount = 0;
        return alert(
          'Adjustment amount should not be greater than Balance Receipt Amount'
        );
      }
    },
    checkBalanceAcc() {
      if (this.unappliedAmount > this.actualBalanceAmount) {
        alert('Amount can not be Greater than Balance Amount');
        this.unappliedAmount = 0.0;
        this.balReceiptAmount = this.actualBalanceAmount;
      }
    },
    realTimeCalculationAmt() {
      this.balReceiptAmount = this.actualBalanceAmount;
      this.balReceiptAmount -= this.unappliedAmount;
    },
    setAdjAmount(unapplyRow, flag) {
      if (this.selectedApplicationType.value === 'MANUALLY') {
        this.tempVar = this.balReceiptAmount;
        if (flag) {
          if (unapplyRow.outstanding_amount > Math.abs(this.balReceiptAmount)) {
            unapplyRow.adj_amount =
              (Math.sign(this.balReceiptAmount) *
                Math.round(this.balReceiptAmount * 100)) /
              100;
            this.balReceiptAmount = 0.0;
          } else {
            unapplyRow.adj_amount =
              Math.round(unapplyRow.outstanding_amount * 100) / 100;
            this.balReceiptAmount =
              this.balReceiptAmount -
              Math.sign(this.balReceiptAmount) * unapplyRow.outstanding_amount;
          }
        } else {
          this.balReceiptAmount +=
            (Math.sign(this.balReceiptAmount) *
              Math.round(unapplyRow.outstanding_amount * 100)) /
            100;
          unapplyRow.adj_amount = 0;
        }
      } else if (this.selectedApplicationType.value === 'AUTOMATIC') {
        // const totalAmount = this.unAppliedReceiptDetailsData.reduce(
        //   (prev, curr) => prev + Math.sign(this.balReceiptAmount) * curr.adj_amount,
        //   0
        // );
        // const filterAmount = this.unAppliedReceiptDetailsData.filter(ele => { if (ele) { ele.adj_amount; return ele } })
        // const mapAmount = filterAmount.map(data=>{ if(data){ data.adj_amount; return data}})
        // const finalAmt = totalAmount.length ? commonHelper.calculateTotal(totalAmount) : 0
        // this.balReceiptAmount = this.balReceiptAmount - this.unappliedAmount;
        this.tempVar = this.balReceiptAmount;
        if (flag) {
          if (unapplyRow.outstanding_amount > Math.abs(this.unappliedAmount)) {
            unapplyRow.adj_amount =
              (Math.sign(this.unappliedAmount) *
                Math.round(this.unappliedAmount * 100)) /
              100;
            let totalAmount = 0;
            this.unAppliedReceiptDetailsData.map(data => {
              if (data.adj_amount) {
                totalAmount += data.adj_amount;
              }
              return data;
            });
            this.balReceiptAmount = this.actualBalanceAmount - totalAmount;
            this.unappliedAmount = 0.0;
          } else {
            unapplyRow.adj_amount =
              Math.round(unapplyRow.outstanding_amount * 100) / 100;
            this.unappliedAmount =
              this.unappliedAmount -
              Math.sign(this.unappliedAmount) * unapplyRow.outstanding_amount;
          }
        } else {
          this.balReceiptAmount +=
            (Math.sign(this.balReceiptAmount) *
              Math.round(unapplyRow.outstanding_amount * 100)) /
            100;
          unapplyRow.adj_amount = 0;
        }
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.AR_ACTIVITY_MASTER_VSET) {
        this.parent_value_set_id = this.sendObj
          ? this.sendObj.ou_id
          : this.applyReceiptRowData.org_ou_id;
        this.depVsetParam = this.sendObj
          ? this.sendObj.entity_id
          : this.applyReceiptRowData.entity_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.APPLY_RECEIPT_TRX_VSET){
        this.parent_value_set_id = this.moduleId;
        this.depVsetParam = this.entityId;
        this.gstAssetParam = this.customerId
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.AR_ACTIVITY_MASTER_VSET) {
        this.activityDetailsData[this.specIndex].activity_details_vset =
          item.activity_id;
        this.activityDetailsData[this.specIndex].activity_details_vset_meaning =
          item.activity_name;
        this.activityDetailsData[this.specIndex].activity_type_vset =
          item.activity_type_vset;
        this.activityDetailsData[this.specIndex].activity_type_vset_meaning =
          item.activity_type_vset_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.APPLY_RECEIPT_TRX_VSET){
        this.trxNo.text = item.trx_number;
        this.trxNo.value = item.trx_hdr_id;


       }
      this.activityDetailsData = JSON.parse(
        JSON.stringify(this.activityDetailsData)
      );
    },
    addNewRow() {
      // if (this.editMode) {
      this.activityDetailsData.push({
        activity_details_vset: null,
        activity_details_vset_meaning: null,
        activity_type_vset: null,
        activity_type_vset_meaning: null,
        amount: null,
        gl_date: null,
        value_date: null,
        description: null
      });
      // }
    },
    removeRow(index) {
      this.activityDetailsData.splice(index, 1);
    },
    checkAmount(activityRowData) {
      if (activityRowData.amount > this.balReceiptAmount) {
        activityRowData.amount = null;
        return alert(
          'Adjustment amount should not be greater than Balance Receipt Amount'
        );
      }
    },
    clearVsetValues(value, index) {
      if (value === 'AR_ACTIVITY_MASTER_VSET') {
        this.activityDetailsData[index].activity_details_vset = null;
        this.activityDetailsData[index].activity_details_vset_meaning = null;
        this.activityDetailsData[index].activity_type_vset = null;
        this.activityDetailsData[index].activity_type_vset_meaning = null;
      } else if ( value === 'APPLY_RECEIPT_TRX_VSET'){
        this.trxNo.text = null;
        this.trxNo.value =null;
      }
    },
    getReceiptAccountingList() {
      const payload = {
        receiptHdrId: this.applyReceiptRowData
          ? this.applyReceiptRowData.receipt_hdr_id
          : this.sendObj
          ? this.sendObj.receipt_hdr_id
          : null,
        applicationModeVset: this.applicationMode
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getAccountingList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (result.length > 0) {
              this.accountingDetailsList = response.data.data;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReceiptApplicatonTypes() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_APPLICATION_TYPE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.applicationTypeList = getValueAndText;
            this.selectedApplicationType =
              getValueAndText && getValueAndText.length > 0
                ? getValueAndText[0]
                : this.keyValue;
          }
        });
    },
    getApplyUnAppliedTransactionDetails() {
      // if (this.trxNo.value) {
      //   let filterData = this.unAppliedReceiptDetailsData.filter(item => {
      //     if (item.trx_number == this.value) {
      //       return item;
      //     }
      //   });
      //   this.trxHdrId = filterData[0].trx_hdr_id;
      // }
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.module_id
          : this.sendObj
          ? this.sendObj.module_id
          : null,
        customer_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.customer_id
          : this.sendObj
          ? this.sendObj.customer_id
          : null,
        // customer_site_id: this.applyReceiptRowData ? this.applyReceiptRowData.customer_site_id : this.sendObj ? this.sendObj.customer_site_id : null,
        entity_id: this.applyReceiptRowData
          ? this.applyReceiptRowData.entity_id
          : this.sendObj
          ? this.sendObj.entity_id
          : null,
        trx_id: this.trxNo.value
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getApplyUnAppliedTransactionDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            const results = response.data.data.page;
            this.unAppliedReceiptDetailsData = results
              .filter(elem => elem.outstanding_amount > 0)
              .map(data => {
                data.adj_amount = 0;
                data.value_date = this.applyReceiptRowData.value_date;
                data.gl_date = this.applyReceiptRowData.gl_date;
                return data;
              });
            this.loopFunction(this.unAppliedReceiptDetailsData);
            this.isBusy = false;
            this.getDefaultDate(store.state.shared.moduleId);
            if (this.trxHdrId && this.trxNo.text) {
              this.getOsTrxAmt();
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.isBusy = false;
        });
    },
    getAppliedSummaryLevel(cn_rcpt_id, type) {
      const payload = {
        receipt_hdr_id: cn_rcpt_id,
        application_mode_vset: this.applicationMode
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getAppliedSummaryLevel', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.appliedDetailsData = response.data.data;
            if (type) {
              this.getReceiptApplicatonTypes();
              // this.getUnAppliedDetailsById();
              if (this.detailsLevel === 'N'){
                this.getUnAppliedTransactionDetails();
              } else {
                this.getApplyUnAppliedTransactionDetails();
              }
               }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};